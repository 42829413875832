<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="primary">
        <span class="white--text title">Review & Agree</span>
      </v-card-title>
      <v-card-text class="grow">
        <v-container fluid fill-height class="pa-0 ma-0">
          <v-layout row wrap v-if="shouldAskForMarketingAndFeedback">
            <v-flex xs12 class="mb-2 pa-2">
              <div class="title">Terms of Use</div>
              <div class="caption" style="float: right;"></div>
              <a href="https://truple.io/termsofuse.html" target="_blank">Click here to view</a>
            </v-flex>
            <v-flex xs12 class="mb-2 pa-2">
              <div class="title">Privacy Policy</div>
              <div class="caption" style="float: right;"></div>
              <a href="https://truple.io/privacy.html" target="_blank">Click here to view</a>
            </v-flex>

            <template v-if="shouldAskForMarketingAndFeedback">
              <v-flex xs12 sm6 class="pa-2">
                <b>Would you like to be notified of new features and offerings?</b>
                <v-radio-group v-model="marketing" :mandatory="true" :column="false" class="pa-0 ma-0"
                   :error-messages=" marketing === null ? ['required'] : []">
                  <v-radio label="yes" value="yes"></v-radio>
                  <v-radio label="no" value="no" class="ml-2"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 sm6 class="pa-2">
                <b>Would you be willing answer questions about your experience with Truple?</b>
                <v-radio-group v-model="feedback" :mandatory="true" :column="false" class="pa-0 ma-0"
                   :error-messages="feedback === null ? ['required'] : []">
                  <v-radio label="yes" value="yes"></v-radio>
                  <v-radio label="no" value="no" class="ml-2"></v-radio>
                </v-radio-group>
              </v-flex>
            </template>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="decline()" text color="secondary" :disabled="accepting" outlined >Decline</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="accept()" color="primary" :disabled="accepting || (marketing === null || feedback === null)">
          <span class="pr-1">Agree</span>
          <v-progress-circular v-if="accepting" :indeterminate="true" :value="0" size="24" class="ml-2"></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreConstants from '../StoreConstants'
import api from '../lib/api'
import auth from '../lib/auth'
import logging from '../lib/logging'

  const shouldAskForMarketingAndFeedback = true

  export default {
    name: "TermsOfService",
    data () {
      return {
        accepting: false,
        shouldAskForMarketingAndFeedback: shouldAskForMarketingAndFeedback,
        marketing: shouldAskForMarketingAndFeedback ? null : 'yes',
        feedback: shouldAskForMarketingAndFeedback ? null : 'yes'
      }
    },
    methods: {
      async accept() {
        try {
          this.accepting = true

          let marketingBool = this.marketing === 'yes' ? true : false
          let feedbackBool = this.feedback === 'yes' ? true : false

          let success = await api.acceptTerms(marketingBool, feedbackBool)
          let accountResponse = await api.getAccountAwait(true)

          this.$store.commit(StoreConstants.commits.setAccount, accountResponse.data)
        } catch(error) {
          logging.notify(error)
          alert('error accepting terms, please email support@truple.io for help.')
        } finally {
          this.accepting = false
        }
      },
      async decline() {
        // alert('You must accept if you wish to continue using Truple.')
        let val = confirm('Do you wish to decline and logout from Truple?')

        if(val) {
          await auth.logoutAwait('terms-declined')
          location.reload()
        }
      }
    },
    computed: {
      show() {
        return !this.$store.getters.termsAccepted
      },
      showDialog: {
        get() {
          return this.show
        },
        set() {
          //do nothing
        }
      }
    }
  }
</script>

<style scoped lang="less">

</style>