/* eslint-disable no-undef */

let dialogCloseFunctions = []

// this gets called by the android webview client, do NOT remove it.
window.closeTopDialog = () => {
  console.log(`closeTopDialog ${dialogCloseFunctions.length}`)
  if(dialogCloseFunctions && dialogCloseFunctions.length > 0) {
    let func = dialogCloseFunctions[dialogCloseFunctions.length - 1] //don't pop--it'll get popped when setFullscreen(false) is called upond dialog closing.
    func()
  }
}

class AndroidInterface {

  installedFromGooglePlay() {
    if(typeof JavascriptToAndroidInterface !== 'undefined') {

      if(typeof JavascriptToAndroidInterface.getInstalledAppPackageName !== 'undefined') {
        //returns com.android.vending if installed from Google Play Store
        const installingPackage = JavascriptToAndroidInterface.getInstalledAppPackageName()
        console.log(`installingPackage = ${installingPackage}`)
        return installingPackage === 'com.android.vending' || installingPackage === 'com.google.android.feedback' || installingPackage === null || installingPackage === undefined
      } else {
        return true //if they're on an older version of the app that doesn't have that function then they installed from Google Play
      }
    } else {
      return null
    }
  }

  getApplicationId() {
    if(typeof JavascriptToAndroidInterface !== 'undefined' && typeof JavascriptToAndroidInterface.getApplicationId !== 'undefined') {
      return JavascriptToAndroidInterface.getApplicationId()
    }
    return null
  }

  getCredentials() {
    if(typeof JavascriptToAndroidInterface !== 'undefined') {
      return JavascriptToAndroidInterface.getCredentials()
    } else {
      return null
    }
  }

  /**
   * Communicates to the android app that a dialog or something else is opened that should allow the app to use the full screen
   * and hide the toolbar.
   * @param val
   */
  setFullscreen(val, closeFunc) {
    console.log(`setFullscreen ${val}`)
    if(typeof JavascriptToAndroidInterface !== 'undefined') {

      if(val) {
        dialogCloseFunctions.push(closeFunc)
      } else {
        dialogCloseFunctions.pop()
      }

      JavascriptToAndroidInterface.setFullscreen(val)
    }
  }

  openWebsiteInBrowser(url) {
    console.log(`openWebsiteInBrowser ${url}`)
    if(typeof JavascriptToAndroidInterface !== 'undefined') {
      JavascriptToAndroidInterface.openWebsiteInBrowser(url)
    }
  }

  openAppPageInBrowser(page) {
    console.log(`openAppPageInBrowser ${page}`)
    if(typeof JavascriptToAndroidInterface !== 'undefined') {
      JavascriptToAndroidInterface.openAppPageInBrowser(page)
    }
  }

  checkForFailedGooglePlayPurchases() {
    if(typeof JavascriptToAndroidInterface !== 'undefined' && typeof JavascriptToAndroidInterface.checkForFailedGooglePlayPurchases !== 'undefined') {
      JavascriptToAndroidInterface.checkForFailedGooglePlayPurchases()
    }
  }

  canUseGooglePlayForPurchases() {
    if(typeof JavascriptToAndroidInterface !== 'undefined' && typeof JavascriptToAndroidInterface.purchaseGooglePlayInAppSubscriptionV2 !== 'undefined') {
      console.log('canUseGooglePlayForPurchases true')
      return true
    }
    if(typeof JavascriptToAndroidInterface !== 'undefined' && typeof JavascriptToAndroidInterface.purchaseGooglePlayInAppSubscription !== 'undefined') {
      console.log('canUseGooglePlayForPurchases true')
      return true
    }
    console.log('canUseGooglePlayForPurchases false')
    return false
  }

  purchaseThroughGooglePlay(subscriptionId, plan, price) {
    //v2
    if(typeof JavascriptToAndroidInterface !== 'undefined' && typeof JavascriptToAndroidInterface.purchaseGooglePlayInAppSubscriptionV2 !== 'undefined') {
      JavascriptToAndroidInterface.purchaseGooglePlayInAppSubscriptionV2(subscriptionId, plan, price)
      return
    }

    //v1
    if(typeof JavascriptToAndroidInterface !== 'undefined' && typeof JavascriptToAndroidInterface.purchaseGooglePlayInAppSubscription !== 'undefined') {
      JavascriptToAndroidInterface.purchaseGooglePlayInAppSubscription(subscriptionId)
      return
    }
  }

  changeThroughGooglePlay(subscriptionId, purchaseToken) {
    console.log(`calling ${JavascriptToAndroidInterface.changeGooglePlayInAppSubscription}`)
    JavascriptToAndroidInterface.changeGooglePlayInAppSubscription(purchaseToken, subscriptionId)
  }
}

export default (new AndroidInterface())