var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "login" },
    [
      _vm.loading
        ? _c("loading")
        : _c(
            "div",
            { staticClass: "basePadding", attrs: { id: "login-container" } },
            [
              _c("amplify-auth", {
                staticClass: "d-flex justify-center",
                attrs: { "initial-page": _vm.initialPage },
                on: { "on-authentication": _vm.onAuthentication }
              }),
              _c(
                "v-snackbar",
                {
                  attrs: { app: "", right: true, top: true, timeout: -1 },
                  model: {
                    value: _vm.showIdleLogout,
                    callback: function($$v) {
                      _vm.showIdleLogout = $$v
                    },
                    expression: "showIdleLogout"
                  }
                },
                [
                  _vm._v(
                    "\n      You've been logged out due to inactivity.\n      "
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
                      attrs: {
                        icon: "",
                        small: "",
                        text: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          _vm.showIdleLogout = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: { app: "", right: true, top: true, timeout: -1 },
                  model: {
                    value: _vm.showCredentialsExpiredLogout,
                    callback: function($$v) {
                      _vm.showCredentialsExpiredLogout = $$v
                    },
                    expression: "showCredentialsExpiredLogout"
                  }
                },
                [
                  _vm._v(
                    "\n      Your credentials have expired.  Please close then reopen the page.\n      "
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
                      attrs: {
                        icon: "",
                        small: "",
                        text: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          _vm.showCredentialsExpiredLogout = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: { right: true, top: true, timeout: 15000 },
                  model: {
                    value: _vm.error,
                    callback: function($$v) {
                      _vm.error = $$v
                    },
                    expression: "error"
                  }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.errorMessage))]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "pr-0 pl-3 pt-0 pb-0 ma-0",
                      attrs: {
                        icon: "",
                        small: "",
                        text: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          _vm.error = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600px" },
                  model: {
                    value: _vm.showResetPasswordDialog,
                    callback: function($$v) {
                      _vm.showResetPasswordDialog = $$v
                    },
                    expression: "showResetPasswordDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v("\n          Reset Password\n        ")
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          "\n          If your account is setup using your "
                        ),
                        _c("b", [
                          _vm._v("Google account, no email will be sent")
                        ]),
                        _vm._v(".\n          "),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          "\n          Instead reset your Google password or if you just want access to Truple try logging in using your Google account.\n        "
                        )
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              on: {
                                click: function($event) {
                                  _vm.showResetPasswordDialog = false
                                }
                              }
                            },
                            [_vm._v("Close")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }