var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600px" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "primary" }, [
            _c("span", { staticClass: "white--text title" }, [
              _vm._v("Review & Agree")
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "grow" },
            [
              _c(
                "v-container",
                {
                  staticClass: "pa-0 ma-0",
                  attrs: { fluid: "", "fill-height": "" }
                },
                [
                  _vm.shouldAskForMarketingAndFeedback
                    ? _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "mb-2 pa-2", attrs: { xs12: "" } },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("Terms of Use")
                              ]),
                              _c("div", {
                                staticClass: "caption",
                                staticStyle: { float: "right" }
                              }),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://truple.io/termsofuse.html",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Click here to view")]
                              )
                            ]
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "mb-2 pa-2", attrs: { xs12: "" } },
                            [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("Privacy Policy")
                              ]),
                              _c("div", {
                                staticClass: "caption",
                                staticStyle: { float: "right" }
                              }),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://truple.io/privacy.html",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Click here to view")]
                              )
                            ]
                          ),
                          _vm.shouldAskForMarketingAndFeedback
                            ? [
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "pa-2",
                                    attrs: { xs12: "", sm6: "" }
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "Would you like to be notified of new features and offerings?"
                                      )
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          mandatory: true,
                                          column: false,
                                          "error-messages":
                                            _vm.marketing === null
                                              ? ["required"]
                                              : []
                                        },
                                        model: {
                                          value: _vm.marketing,
                                          callback: function($$v) {
                                            _vm.marketing = $$v
                                          },
                                          expression: "marketing"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "yes", value: "yes" }
                                        }),
                                        _c("v-radio", {
                                          staticClass: "ml-2",
                                          attrs: { label: "no", value: "no" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  {
                                    staticClass: "pa-2",
                                    attrs: { xs12: "", sm6: "" }
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "Would you be willing answer questions about your experience with Truple?"
                                      )
                                    ]),
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "pa-0 ma-0",
                                        attrs: {
                                          mandatory: true,
                                          column: false,
                                          "error-messages":
                                            _vm.feedback === null
                                              ? ["required"]
                                              : []
                                        },
                                        model: {
                                          value: _vm.feedback,
                                          callback: function($$v) {
                                            _vm.feedback = $$v
                                          },
                                          expression: "feedback"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: { label: "yes", value: "yes" }
                                        }),
                                        _c("v-radio", {
                                          staticClass: "ml-2",
                                          attrs: { label: "no", value: "no" }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    color: "secondary",
                    disabled: _vm.accepting,
                    outlined: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.decline()
                    }
                  }
                },
                [_vm._v("Decline")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled:
                      _vm.accepting ||
                      _vm.marketing === null || _vm.feedback === null
                  },
                  on: {
                    click: function($event) {
                      return _vm.accept()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "pr-1" }, [_vm._v("Agree")]),
                  _vm.accepting
                    ? _c("v-progress-circular", {
                        staticClass: "ml-2",
                        attrs: { indeterminate: true, value: 0, size: "24" }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }