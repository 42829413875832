import bugsnag from '@bugsnag/js'

const client = bugsnag({
  apiKey: '3a85026060e0a4be76c4557f34590279',
  appVersion: process.env.VUE_APP_VERSION,
  beforeSend: report => {
    if (report.request.url.toLowerCase().indexOf('emailimages') != -1) {
      report.request.url = report.request.url.substring(0, report.request.url.toLowerCase().indexOf('dailyemail'))
    }

    if(process.env.VUE_APP_ENV === 'dev') {
      report.ignore()
    }
  }
})

export default {
  notify: (msg) => {

    // eslint-disable-next-line no-console
    console.log(`error: ${msg}`)
    // eslint-disable-next-line no-console
    console.log(new Error().stack)
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(msg, null, 2))
    client.notify(msg, {})
  },
  use: (a, b) => {
    client.use(a, b)
  }
}