import accountClientFactory from "./apigClient";
import AWSClockSkewHandler from "../AWSClockSkewHandler";
import store from "../../store";

class AccountApi {
  constructor() {
    this.inited = false
  }

  init() {
    if(!this.inited) {
      this.inited = true
      console.log(process.env.VUE_APP_ACCOUNT_URL)
      this.client = accountClientFactory.newClient(store.state.credentials, process.env.VUE_APP_ACCOUNT_URL)
    }
  }

  async getJwtToken(forAccountId) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.e2eGet({
        forAccountId: forAccountId,
        deviceUUID: store.state.deviceUUID
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async addDohDevice(device) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.devicesDohAddPost({
        deviceUUID: store.state.deviceUUID
      }, device)
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async recordE2EEAction(action) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.accountsE2eePut({
        deviceUUID: store.state.deviceUUID
      }, {
        action: action
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async acceptDeviceRequest(body) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.devicesRequestGrantPost({
        deviceUUID: store.state.deviceUUID
      }, body)
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async blockDeviceRequest(ip) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.devicesRequestBlockPost({
        deviceUUID: store.state.deviceUUID,
        ip: ip
      })
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }

  async rejectDeviceRequest(body) {
    this.init()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.devicesRequestRejectPost({
        deviceUUID: store.state.deviceUUID
      }, body)
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return result
  }
}

export default (new AccountApi())
