<template>
  <v-dialog v-model="showDialog" max-width="600px" scrollable persistent :fullscreen="$vuetify.breakpoint.smAndDown" @close="close">
    <v-card>
      <v-card-title>
        <span v-if="currentQuestion === 0">Feedback Survey</span>
        <v-spacer />
        <v-btn icon @click="close" :disabled="submitting"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>

        <survey-question v-if="currentQuestion === 0" :back-button-text="null" :next-disabled="!answers.bestAccountabilityApp" @on-next="currentQuestion = 1">
          <yes-no :question="`Is ${appName} the best parental monitoring/accountability app you've used?`" v-model="answers.bestAccountabilityApp" />
        </survey-question>

        <survey-question
            v-else-if="currentQuestion === 1 && answers.bestAccountabilityApp === 'yes'"
            :next-disabled="false"
            next-button-text="Submit"
            @on-back="currentQuestion = 0"
            :next-loading="submitting"
            @on-next="submit">
          <div>
            <p class="text-h5">Please help our ratings reflect that {{appName}} is the best.</p>

            <p>
              <span class="font-weight-bold">Why should you care about {{appName}}'s rating?</span><br />
              Better ratings <v-icon>arrow_right_alt</v-icon> Easier time marketing <v-icon>arrow_right_alt</v-icon> {{appName}} has more resources to dedicate to improving the product.</p>
            <p><b>A 5 star rating helps.</b>  We only get 10 or so reviews / month, so <span class="text-decoration-underline">every one counts</span>.<br />
              <b>A 1-4 star rating <span class="text-decoration-underline">harms</span> {{appName}}.</b>  <a class="font-italic ml-3 caption" @click="showLearnWhy = !showLearnWhy">{{ showLearnWhy ? '(close)' : '(learn why 4 star ratings do harm)'}}</a></p>
            <v-expand-transition>
              <p v-if="showLearnWhy">
                App ratings are used to position {{appName}} either above or below other apps in search results.
                Most people only install the top 1-2 apps, so having a good position is very important.
                A 5 star rating should be given when the app is better than the alternatives.  4 star or lower ratings will position other apps above {{appName}}.
                A 5 star rating does not require a perfect app, though we'll work towards that continually.
              </p>
            </v-expand-transition>
            <v-btn
                href="https://play.google.com/store/apps/details?id=com.camhart.netcountable"
                @click="answers.clickedLeaveRating = true"
                target="_blank"
                color="primary"
                :disabled="submitting"
                class="mb-1 text-none"
                block>
              Leave Rating on Google Play
            </v-btn>
            <p class="caption font-italic">Leaving a rating is optional, but we greatly appreciate it.  If you don't want to leave one, just click Submit.</p>
            <v-btn text @click="showAlreadyLeftOne = true">I've already left a review</v-btn>
            <v-dialog v-model="showAlreadyLeftOne" width="400px">
              <v-card>
                <v-card-title>
                  Thank you!
                </v-card-title>
                <v-card-text>
                  If it's been a while, you can consider updating your review.  Updating reviews help due to Google's new algorithm that weighs recent reviews more heavily.
                  <br />
                  <br />
                  Otherwise just submit the survey without updating your review and know that we're grateful for your support.
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn @click="showAlreadyLeftOne = false" color="primary">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </survey-question>
        <survey-question v-else-if="currentQuestion === 1 && answers.bestAccountabilityApp === 'no'"
                         :next-disabled="!answers.improveDescription_freetext || submitting"
                         next-button-text="Submit"
                         @on-back="currentQuestion = 0"
                         :next-loading="submitting"
                         @on-next="submit">
          <long-text :question="`How can we improve ${appName} so it is the best?`" v-model="answers.improveDescription_freetext" :autofocus="true" />
        </survey-question>

        <survey-question v-if="currentQuestion === 10" :back-button-text="false" next-button-text="Close" @on-next="close" :next-disabled="submitting" :next-loading="submitting">
          <div class="mt-6">
            <div class="title">Thanks for the feedback!</div>
            <div>
              We plan on asking every 3 months for feedback.  We don't want these to get too annoying, but it's important for us to hear from you so we understand how well we're serving you.  Thank you for taking the time.
            </div>
          </div>
        </survey-question>



<!--        Please help our app ratings reflect that.  Most of our apps average mid 4 star ratings.  Ratings of 5 stars helps us, ratings below 5 stars hurts ${appName} and causes us to have to spend more marketing money to acquire customers (which ultimately gets passed on as increased expense to you, our customers).  We know ${appName} isn't perfect--we'll continue working to improve it every day.  But a good rating helps us to build better products.  A 5 star rating does not require a perfect app.  A 5 star rating should be given when the app is better than the alternatives.  Why?  Because app ratings aren't viewed in isolation against some theoretical "ideal" app.  They're used to position ${appName}'s app against it's competitors.-->

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import YesNo from "@/components/surveys/questions/YesNo";
import SurveyQuestion from "@/components/surveys/SurveyQuestion";
import LongText from "@/components/surveys/questions/LongText";
import SurveyApi from "@/aws/survey/SurveyApi";
import Utils from "@/Utils";
import StoreConstants from "@/StoreConstants";

export default {
  name: "SurveyFeedback",
  components: {LongText, SurveyQuestion, YesNo},
  props: {
    value: {}
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      showLearnWhy: false,
      showAlreadyLeftOne: false,
      currentQuestion: 0,
      showDialog: this.value,
      submitting: false,
      answers: {
        improveDescription_freetext: '', //appending _freetext explains to our backend to store this value differently since its free form text.
        clickedLeaveRating: false,
        bestAccountabilityApp: null
      }
    }
  },
  computed: {
  },
  methods: {
    async close() {
      this.submitting = true
      await this.$store.dispatch(StoreConstants.actions.loadAccount)
      this.submitting = false
      this.showDialog = false
      this.$emit('input', false)
      this.$emit('on-close')
    },
    async submit() {
      try {
        this.submitting = true
        const surveyCategory = 'feedback'
        await SurveyApi.submitSurveyResults(surveyCategory, this.answers)
        this.currentQuestion = 10
      } catch(e) {
        // eslint-disable-next-line no-console
        console.log(e)
        alert('an error occurred')
        throw e
      } finally {
        this.submitting = false
      }

    }
  },
  watch: {
    value: function(newVal) {
      this.showDialog = newVal
    }
  }
}
</script>

<style scoped>

</style>