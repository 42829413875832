import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import auth from './auth'
import store from '../store'
import Login from "../components/Login";
// import Home from "../components/Home";
// import Emails from "../components/Emails";
// import Filter from "../components/Filter"
// import Devices from "../components/devices/Devices";
// import Account from "../components/accounts/Account";
// import Contact from "../components/Contact";
// import UpdateLog from "../components/UpdateLog"
// import Discounts from "../components/Discounts"
// import DailyEmailImages from "../components/DailyEmail/DailyEmailImages";
// import DailyEmailUnsubscribe from "../components/DailyEmail/DailyEmailUnsubscribe";
// import DailyEmailBlock from "../components/DailyEmail/DailyEmailBlock";
import StoreConstants from '../StoreConstants'
// import HomeV2 from "../components/HomeV2";
// import DNSCloakInstallInstructions from "../components/DnsCloak/DNSCloakInstallInstructions";
// import Debug from "../components/debug/Debug";
import MobileBackButtonHelper from "../services/MobileBackButtonHelper";
import ReferrerHelper from "@/services/ReferrerHelper";

function requireAuth(to, from, next) {
  console.log('TIMING: requireAuth')
  let loginPath = '/login'

  ReferrerHelper.run(to.query.href, to.query.referrer)

  let query = {}

  if(to.query.loginAs) {
    query.loginAs = to.query.loginAs
  }

  if(to.query.idleLogout) {
    query.idleLogout = to.query.idleLogout
  }

  if(to.query.inApp) {
    query.inApp = to.query.inApp
    store.commit(StoreConstants.commits.setInApp)
  }

  if(!auth.isLoggedIn()) {
    if(to.fullPath.trim().length > 0 ){

      query.redirect = to.fullPath

      next({
        path: loginPath,
        query: query
      })
    } else {
      next({
        path: loginPath,
        query: query
      })
    }
  } else {
    next()
  }
}

const vr = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      alias: ['/#/login'],
      component: Login
    },
    {
      path: '/postpayment',
      name: 'PostPayment',
      component: () => import('../components/accounts/PostPayment'),
      alias: ['/#/postpayment'],
      beforeEnter: requireAuth
    },
    {
      path: '/home',
      name: 'Home',
      // component: HomeV2,
      component: () => import('../components/HomeV2'),
      alias: ['/', '/#/'],
      beforeEnter: requireAuth
    },
    {
      path: '/allscreenshots',
      name: 'Screenshots',
      // component: Home,
      component: () => import('../components/Home'),
      alias: ['/#/allscreenshots'],
      beforeEnter: requireAuth
    },
    {
      path: '/stats',
      name: 'Stats',
      component: () => import('../components/HomeV2'),
      alias: ['/#/stats'],
      beforeEnter: requireAuth
    },
    {
      path: '/devices',
      name: 'Devices',
      // component: Devices,
      component: () => import('../components/devices/Devices'),
      alias: ['/#/devices'],
      beforeEnter: requireAuth
    },
    {
      path: '/filter',
      name: 'Filtering',
      // component: Filter,
      component: () => import('../components/Filter'),
      alias: ['/#/filter'],
      beforeEnter: requireAuth
    },
    {
      path: '/emails',
      name: 'Emails',
      component: () => import('../components/Emails'),
      alias: ['/#/emails'],
      beforeEnter: requireAuth
    },
    {
      path: '/account',
      name: 'Account',
      component: () => import('../components/accounts/Account'),
      alias: ['/#/account'],
      beforeEnter: requireAuth
    },
    {
      path: '/refund',
      name: 'Refund',
      component: () => import('../components/accounts/Refund'),
      alias: ['/#/refund'],
      beforeEnter: requireAuth
    },
    {
      path: '/setup',
      name: 'Setup',
      component: () => import('../components/setup/SetupGuide'),
      alias: ['/#/setup'],
      beforeEnter: requireAuth
    },
    {
      path: '/contact',
      alias: ['/#/contact'],
      name: 'Contact',
      // component: Contact,
      component: () => import('../components/Contact'),
      beforeEnter: requireAuth
    },
    {
      path: '/updates',
      name: 'Updates',
      // component: UpdateLog,
      component: () => import('../components/UpdateLog'),
      alias: ['/#/updates'],
      beforeEnter: requireAuth
    },
    {
      path: '/discounts',
      alias: ['/#/discounts', '/#/discount', '/discount'],
      name: 'Discounts',
      component: () => import('../components/Discounts'),
      beforeEnter: requireAuth
    },
    {
      path: '/dailyEmailImages',
      alias: ['/#/dailyEmail/images', '/dailyEmail/images'],
      name: 'Daily Email Images',
      // component: DailyEmailImages,
      component: () => import('../components/DailyEmail/DailyEmailImages'),
      beforeEnter: requireAuth
    },
    {
      path: '/dailyEmailUnsubscribe',
      alias: ['/#/dailyEmail/unsubscribe', '/dailyEmail/unsubscribe'],
      name: 'Daily Report Recipient Unsubscribe',
      // component: DailyEmailUnsubscribe
      component: () => import('../components/DailyEmail/DailyEmailUnsubscribe')
    },
    {
      path: '/dailyEmailBlock',
      alias: ['/#/dailyEmail/block', '/dailyEmail/block'],
      name: 'Daily Report Recipient Block',
      // component: DailyEmailBlock
      component: () => import('../components/DailyEmail/DailyEmailBlock')
    },
    {
      path: '/dnscloak',
      alias: ['/#/dnscloak'],
      name: 'DNSCloak Install Instructions',
      // component: DNSCloakInstallInstructions
      component: () => import('../components/DnsCloak/DNSCloakInstallInstructions')
    },
    {
      path: '/rating',
      alias: ['/#/rating'],
      name: 'Debug',
      component: () => import('../components/ratings/RatingCorrection'),
      beforeEnter: requireAuth
    },
    {
      path: '/debug',
      alias: ['/#/debug', '/debug'],
      name: 'Debug',
      component: () => import('../components/debug/Debug'),
      beforeEnter: requireAuth
    },
    {
      path: '/referrer/fortsafety',
      alias: ['/#/referrer/fortsafety'],
      name: 'FortSafety',
      component: () => import('../components/referrer/FortSafety')
    },
  ]
})

// This is required for mobile back button to close dialogs.  Without it, the router guards in mobileBackButtonMixin.js
// don't get called.  I don't fully understand why, but see
// https://forum.vuejs.org/t/router-beforeeach-if-manually-input-adress-in-browser-it-does-not-work/12461/3
// vr.beforeEach((to, from, next) => {
//   console.log('root before each called')
//   next()
// })

MobileBackButtonHelper.registerVueRouter(vr)


export default vr