<template>
  <div>
    <v-tooltip
        :top="$store.state.isMobile"
        :right="!$store.state.isMobile"
        dark

        color="rgba(0, 0, 0, 0.95)">
      <template v-slot:activator="{on}">
        <v-text-field
            outlined
            v-on="on"
            v-model="myAuthData.password"
            @change="authDataChange"
            @animationstart="authDataChange"
            label="Password"
            placeholder=""
            type="password"
            :error="!passwordIsValidWithoutConfirm && !!myAuthData.password"
            autocomplete
            :disabled="disabled"
        />
      </template>

      <div>
        <div class="text--black">Your password should contain:</div>
        <div :class="{ 'text--theme--primary' : passwordLongEnough, 'text--theme--warning' : !passwordLongEnough}">
          • length of 8+
        </div>
        <div :class="{ 'text--theme--primary' : passwordHasLowerCase, 'text--theme--warning' : !passwordHasLowerCase}">
          • lowercase letters
        </div>
        <div :class="{ 'text--theme--primary' : passwordHasUpperCase, 'text--theme--warning' : !passwordHasUpperCase}">
          • uppercase letters
        </div>
        <div :class="{ 'text--theme--primary' : passwordHasNumber, 'text--theme--warning' : !passwordHasNumber}">
          • numbers
        </div>
        <div :class="{ 'text--theme--primary' : passwordHasSpecialCharacter, 'text--theme--warning' : !passwordHasSpecialCharacter}">
          • special character
        </div>
      </div>
    </v-tooltip>

    <v-text-field
        v-if="showConfirm"
        :color="confirmPassword !== myAuthData.password ? 'warning' : 'primary'"
        outlined
        label="Confirm Password"
        placeholder=""
        v-model="confirmPassword"
        type="password"
        :hint="confirmPassword !== myAuthData.password ? `Passwords don't match` : ''"
        :persistent-hint="true"
        :error="confirmPassword !== myAuthData.password"
        :disabled="disabled"
    />
  </div>

</template>

<script>
export default {
  name: "PasswordTextField",
  props: {
    authData: {
      type: Object,
      required: true
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myAuthData: this.authData,
      confirmPassword: ''
    }
  },
  computed: {
    passwordIsValidWithoutConfirm() {
      return this.passwordLongEnough &&
          this.passwordHasSpecialCharacter &&
          this.passwordHasNumber &&
          this.passwordHasUpperCase &&
          this.passwordHasLowerCase
    },
    passwordIsValid() {
      return this.passwordIsValidWithoutConfirm &&
          (!this.showConfirm || (this.confirmPassword === this.myAuthData.password))
    },
    passwordLongEnough() {
      return this.authData.password.length >= 8
    },
    passwordHasUpperCase() {
      return (/[A-Z]/.test(this.authData.password))
    },
    passwordHasLowerCase() {
      return (/[a-z]/.test(this.authData.password))
    },
    passwordHasNumber() {
      return (/[0-9]/.test(this.authData.password))
    },
    passwordHasSpecialCharacter() {
      //see https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
      let special = ['=', '+', '-', '^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '"', '!', '@', '#', '%', '&', '/', '\\', ',', '>', '<', '\'', ':', ';', '|', '_', '~', '`']

      for(let a of special) {
        if(this.authData.password.indexOf(a) !== -1) {4
          return true
        }
      }

      return false
    }
  },
  methods: {
    authDataChange() {
      this.$emit('auth-data-change', this.myAuthData)
    }
  },
  watch: {
    passwordIsValid(val) {
      console.log(`passwordIsValid ${val}`)
      this.$emit('on-password-valid-change', val)
    }
  }
}
</script>

<style scoped>

</style>