import Auth from '@aws-amplify/auth'
import logging from "./logging";
import store from "../store"
import mainClientFactory from '../aws/base/apigClient'
import AWSClockSkewHandler from "../aws/AWSClockSkewHandler";
import SegmentTracking from "../services/SegmentTracking";

const IDLE_THRESHOLD = 60 * 60 * 1000
let lastAction = Date.now()
let idleTimeoutHandle = null;

let resolveCredentialsSetPromise = null
let credentialsSetPromise = new Promise((resolve) => {
  resolveCredentialsSetPromise = resolve
})

let refreshPromise = null

class Api {
  updateLastAction() {
    lastAction = Date.now()
    if(idleTimeoutHandle) {
      clearTimeout(idleTimeoutHandle)
    }

    if(!store.state.keepMeLoggedIn) {
      console.log('launching idle timeout handler')
      idleTimeoutHandle = setTimeout(() => {
        if(!store.state.keepMeLoggedIn) {
          console.log('idleTimeoutHandle')
          this.forceLogout('idleLogout')
        }
      }, IDLE_THRESHOLD)
    }
  }

  forceLogout(logoutType) {
    if(this.auth) {
      this.auth.logoutAwait('idle-logout')
    }
    window.location.href = window.location.origin + `/login?${logoutType}=true`
  }

  async handleIdleLogout() {
    let shouldLogout = await this.shouldLogout()
    if(shouldLogout) {
      this.forceLogout('idleLogout')
    } else {
      this.updateLastAction()
    }
  }

  async attemptTokenRefresh() {
    let err
    let currentSession = await Auth.currentSession().catch(e => {
      err = e
    })
    if(err) throw err

    console.log(`currentSession`)
    console.log(currentSession)

    if(this.auth.idToken && (currentSession.idToken.jwtToken !== this.auth.idToken)) {
      await this.auth.setCurrentSession(currentSession)
      console.log('idToken refreshed!')
    } else {
      console.log('attempted idToken refresh, but got the same token back')
    }
  }

  async shouldLogout() {
    if(!this.expires_at) {
      //not logged in, so no need to logout
      return false
    }
    else if(store.state.keepMeLoggedIn && !store.state.inApp) {
      if(Date.now() > this.expires_at) {
        console.log('attempting idToken refresh...')

        try {
          if(refreshPromise) {
            console.log('awaiting existing refreshPromise')
            await refreshPromise
          } else {
            refreshPromise = await this.attemptTokenRefresh()
          }
          return false;
        } catch(e) {
          return true
        }
      } else {
        console.log(`not attempting refresh ${Date.now() - this.expires_at}`)
        return false
      }
    } else {
      return Date.now() - lastAction > IDLE_THRESHOLD;
    }
  }

  setUnauthedCredentials() {
    console.log(process.env.VUE_APP_NETCOUNTABLE_URL)
    console.log('setUnauthedCredentials')
    if(!this.client) {
      // eslint-disable-next-line
      this.client = mainClientFactory.newClient({
        region: 'us-west-2',
        defaultContentType: 'application/json',
        defaultAcceptType: 'application/json'
      }, process.env.VUE_APP_NETCOUNTABLE_URL)
    }

    resolveCredentialsSetPromise()
  }

  setCredentials(auth, accessKey, secretKey, sessionToken, expires_at) {

    console.log('setCredentials called')

    console.log(process.env.VUE_APP_NETCOUNTABLE_URL)

    // eslint-disable-next-line
    this.client = mainClientFactory.newClient({
      accessKey: accessKey,
      secretKey: secretKey,
      sessionToken: sessionToken,
      region: 'us-west-2',
      defaultContentType: 'application/json',
      defaultAcceptType: 'application/json'
    }, process.env.VUE_APP_NETCOUNTABLE_URL)
    this.expires_at = expires_at
    this.auth = auth

    resolveCredentialsSetPromise()
  }

  async createAccountAwait(request) {
    await credentialsSetPromise
    await this.handleIdleLogout()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.accountsPut({
        deviceUUID: store.state.deviceUUID
      }, request)
    })
    .catch(err => {
      logging.notify(err)
      error = err
    })
    if(error) throw error

    return result
  }

  async getImageRecordsAwait(startDay, endDay, screenshotsForIdentityId, accountId) {
    await this.handleIdleLogout()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.imagesGetImageRecordsPost(
        {
          deviceUUID: store.state.deviceUUID,
          screenshotsForIdentityId: screenshotsForIdentityId,
          forAccountId: accountId ? accountId : null
        },
        {
          startDay: startDay,
          endDay: endDay
        }
      )
    }).catch(err => {
        error = err
    })

    if(error) throw error

    return result

  }

  async getDevicesAwait () {
    await this.handleIdleLogout()
    await credentialsSetPromise

    let err
    let devices = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.accountsDevicesGet({
        deviceUUID: store.state.deviceUUID
      }, {})
    }).catch(error => {
      err = error
    })
    if(err) throw err
    return devices
  }

  async createInviteCodeAwait(request) {
    await this.handleIdleLogout()
    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.accountsInviteCodePost({ deviceUUID: store.state.deviceUUID }, request)
    }).catch(err => {
      logging.notify(err)
      error = err
    })
    if(error) throw error

    return result
  }

  async updateDevicesAwait(request) {
    await this.handleIdleLogout()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.accountsDevicesPut({singleDevice: false, deviceUUID: store.state.deviceUUID}, request)
    }).catch(err => {
      logging.notify(err)
      error = err
    })
    if(error) throw error

    return result
  }

  async acceptTerms(marketing, feedback) {

    await this.handleIdleLogout()

    let error
    let success = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.accountsAcceptTermsPost({
        marketing: marketing,
        feedback: feedback,
        deviceUUID: store.state.deviceUUID
      }, {})
    }).catch(err => {
      error = err
    })
    if(error) throw error

    return success
  }

  async getAccountAwait(loadPaymentInfo) {
    await this.handleIdleLogout()
    await credentialsSetPromise

    let err
    let account = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.accountsGet({
        deviceUUID: store.state.deviceUUID
      }, {}, {
        queryParams: {
          loadPaymentInfo: loadPaymentInfo,
        }
      })
    }).catch(error => {
      err = error
    })

    if(err) throw err

    return account
  }

  async checkCouponAwait(coupon) {
    await this.handleIdleLogout()

    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.couponPost({
        deviceUUID: store.state.deviceUUID
      }, {coupon: coupon})
    }).catch(err => {
      logging.notify(err)
    })

    return result
  }

  async subscribeAwait(request) {
    await this.handleIdleLogout()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.subscribePost({
        deviceUUID: store.state.deviceUUID
      }, request)
    }).catch(err => {
      error = err
    })

    if(error) {
      throw error
    }
    return result
  }

  // async cancelSubscriptionAwait(cancelReasons) {
  //   await this.handleIdleLogout()
  //
  //   let error
  //   let response = AWSClockSkewHandler.handleClockSkew(() => {
  //     return this.client.subscribeCancelPost({
  //       deviceUUID: store.state.deviceUUID
  //     }, cancelReasons)
  //   }).catch(err => {
  //     error = err
  //     logging.notify(error)
  //   })
  //   if(error) throw error
  //
  //   return response
  // }

  async getEmails() {
    await this.handleIdleLogout()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.emailGet({
        deviceUUID: store.state.deviceUUID
      }, {})
    }).catch((err) => {
      error = err
    })
    if(error) throw error

    return result
  }

  async addEmailAwait(email, frequency, identities, alertImmediately, phoneNumber, canView) {
    await this.handleIdleLogout()

    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.emailAddPost({
          deviceUUID: store.state.deviceUUID
        }, {
          email: email,
          frequency: frequency,
          identities: identities,
          alertImmediately: alertImmediately,
          phoneNumber: phoneNumber,
          canView: canView,
          product: process.env.VUE_APP_PRODUCT
        }
      )
    }).catch((err) => {
      error = err
      logging.notify(error)
    })
    if(error) throw error

    return result
  }

  async removeEmail(email, frequency) {
    await this.handleIdleLogout()
    let error
    let result = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.emailRemovePost(
        {deviceUUID: store.state.deviceUUID},
        {email: email, frequency: frequency}
      )
    }).catch((err) => {
      error = err
    })

    if(error) throw error

    return result
  }

  async deleteImages(request) {
    await this.handleIdleLogout()

    let error
    let ret = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.imagesDeletePost({
        deviceUUID: store.state.deviceUUID
      }, request, {})
    }).catch(err => {
      error = err
    })

    if(error) throw error
    return ret
  }

  async dailyEmailImagesAwait(request) {
    let error
    let ret = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.dailyEmailImagesGet(request, null, {})
    }).catch(err => {
      error = err
      console.log(err)
    })
    if(error) throw error
    return ret
  }

  async dailyEmailUnsubscribe(request) {
    let error
    let ret = await AWSClockSkewHandler.handleClockSkew(() => {
      return this.client.dailyEmailUnsubscribeGet({}, {}, {
        queryParams: request
      })
    }).catch(err => {
      error = err
    })

    if(error) throw error

    return ret
  }
}

export default (new Api())