class StoreConstants {
  constructor() {
    this.commits = {
      setHref: 'setHref',
      setReferrer: 'setReferrer',
      setKeepMeLoggedIn: 'setKeepMeLoggedIn',
      setAccount: 'setAccount',
      setDevices: 'setDevices',
      setGoogleUser: 'setGoogleUser',
      setMobile: 'setMobile',
      setBrowser: 'setBrowser',
      setInApp: 'setInApp',
      setCredentials: 'setCredentials',
      setLoggedInEmail: 'setLoggedInEmail',
      setDeviceUUID: 'setDeviceUUID',
      setInAppCredentialsExpiresAt: 'setInAppCredentialsExpiresAt',
      setAccountE2EEHelper: 'setAccountE2EEHelper',
      setViewSettings: 'setViewSettings',
      showOwnScreenshots: 'showOwnScreenshots',
      setLoadedFromAndroidApp: 'setLoadedFromAndroidApp',
      setUnknown: 'setUnknown',
      setSubmitAllAutomatically: 'setSubmitAllAutomatically'
    }

    this.actions = {
      loadAccount: 'loadAccount',
      loadDevices: 'loadDevices'
    }
  }
}

export default (new StoreConstants())